export type FindIconPath = {
	type: "small" | "medium" | "big";
	data: {
		type: "small" | "medium" | "big";
		file: {
			path: string;
		};
	}[];
};

export const findIconPath = ({ data, type }: FindIconPath): string => {
	return data.find((icon) => icon.type === type).file.path;
};
